import ThemisNotificationSnackbar from "@/components/shared/notification-snackbar"
import ThemisTermsOfUseLogin from "@/components/terms-of-use/login"
import { mapGetters, mapActions } from "vuex"
import { hasAccessToRoute, hasToShowSubModule } from "@/utils/access"
import SUPPORT_MENU_MODULES from "@/constants/support-menu-modules"
import { PAGES_WITH_BACKGROUND_COLOR, ACCESS_MANAGEMENT_ROUTES,  FORMS_ROUTES, SYSTEM_ROUTES, ISSUE_CUSTOMISATION_ROUTES } from "@/constants"

export default {
  name      : "Default",
  components: {
    ThemisNotificationSnackbar,
    ThemisTermsOfUseLogin
  },
  data() {
    return {
      module                : null,
      drawer                : true,
      navigationMini        : false,
      sidePanelMini         : false,
      showUserMenu          : false,
      showSupportMenu       : false,
      hasToDisplayTermsOfUse: false
    }
  },
  computed: {
    ...mapGetters({
      isPageFreezed               : "shared/isPageFreezed",
      isNotified                  : "shared/isNotified",
      breadcrumbs                 : "shared/breadcrumbs",
      notification                : "shared/notification",
      loggedInUser                : "auth/loggedInUser",
      isLoggedIn                  : "auth/isLoggedIn",
      loggedInUserRoleTypes       : "auth/loggedInUserRoleTypes",
      pageTitle                   : "shared/pageTitle",
      isIssueFormsEnabled         : "configurations/isIssueFormsEnabled",
      isIssueFieldsEnabled        : "configurations/isIssueFieldsEnabled",
      isReporterIntakeFormsEnabled: "configurations/isReporterIntakeFormsEnabled",
      termsOfUse                  : "configurations/termsOfUse",
      isUpdatingTermsOfUseAccepted: "users/isUpdatingTermsOfUseAccepted",
      isReplyTemplatesEnabled     : "configurations/isReplyTemplatesEnabled"
    }),
    isMediumAndBelowScreen() {
      return this.$vuetify.breakpoint.mdAndDown
    },
    isLoggedInUserAdmin() {
      return this.loggedInUserRoleTypes.includes("global")
    },
    modules() {
      const modules               = [this.$DASHBOARD]
      const loggedInUserHasAccess = module => {
        return hasAccessToRoute(module.roleTypes)
      }
      const shouldShowSubModule   = module => {
        return hasToShowSubModule(module.action)
      }
      if(this.loggedInUserRoleTypes.length) {
        for (const module of this.$MODULES) {
          if (module.items) {
            const subModules = new Array()
            for (const subModule of module.items) {
              if (loggedInUserHasAccess(subModule) && shouldShowSubModule(subModule)) {
                subModules.push(subModule)
              }
            }
            if (subModules.length > 0) {
              modules.push({
                icon : module.icon,
                title: module.title,
                items: subModules
              })
            }
          } else if(loggedInUserHasAccess(module)) {
            modules.push(module)
          }
        }
      }

      return modules
    },
    pageTitleForDisplay() {
      if (this.pageTitle.useTranslation) {
        return this.$t(this.pageTitle.value)
      }
      return this.pageTitle.value
    },
    changeBackgroundColorForPage() {
      return PAGES_WITH_BACKGROUND_COLOR.includes(this.$route.name)
    },
    routeName() {
      return this.$route.name
    },
    termsOfUseValue() {
      return this.termsOfUse?.value
    }
  },
  methods: {
    ...mapActions({
      suppressNotification: "shared/suppressNotification",
      updateUser          : "users/updateUser"
    }),
    handleSupportRequest(module) {
      if(module.title === SUPPORT_MENU_MODULES[1].title) {
        if (this.isLoggedInUserAdmin) {
          window.open(module.action)
        } else {
          this.$router.push({ name: "support" })
        }
      } else {
        window.open(module.action)
      }
      this.showSupportMenu = false
    },
    breadcrumbForDisplay(item) {
      if (item.useTranslation) {
        return this.$t(item.text)
      }
      return item.text
    },
    handleClickOnAvatar() {
      this.showUserMenu = !this.showUserMenu
    },
    logout() {
      this.$router.push({ name: "logout" })
    },
    acceptTermsOfUse() {
      this.updateUser({
        id                : this.loggedInUser.id,
        termsOfUseAccepted: true
      })
    },
    getBackgroundColorForGroupListIcon(module) {
      const routeName         = this.$route.path.split("/")[1]
      const isSubModuleActive = module.items.find(subModule =>
        subModule.action === routeName
      )
      if (this.navigationMini && !isSubModuleActive) {
        return  "grey"
      } else {
        return  "secondary"
      }
    },
    checkForActiveModule(item) {
      return (ACCESS_MANAGEMENT_ROUTES.includes(this.$route.name) && item.title === "1100") ||
      (FORMS_ROUTES.includes(this.$route.name) && item.title === "1101") ||
      (SYSTEM_ROUTES.includes(this.$route.name) && item.title === "1102") ||
      (ISSUE_CUSTOMISATION_ROUTES.includes(this.$route.name) && item.title === "890")
    }
  },
  watch: {
    routeName: {
      immediate: true,
      handler  : function(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.sidePanelMini = false
        }
      }
    },
    isMediumAndBelowScreen: {
      immediate: true,
      handler  : function(newValue) {
        this.navigationMini = newValue
        this.sidePanelMini  = newValue
      }
    },
    isLoggedIn: {
      immediate: true,
      handler  : function(newValue) {
        this.hasToDisplayTermsOfUse = newValue &&
          !this.loggedInUser.termsOfUseAccepted &&
          !!this.termsOfUseValue?.length
      }
    },
    "loggedInUser.termsOfUseAccepted": {
      handler: function(newValue) {
        if (newValue) {
          this.hasToDisplayTermsOfUse = false
        }
      }
    }
  }
}
