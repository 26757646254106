/**
 * @file It contains all the action methods which are used to mutate state asynchronously
 */
import { HTTP_STATUS_CODE, MFA, IDP_LOGIN_URL, IDP_LOGOUT_URL, COOKIE_OPTIONS, COGNITO_AUTHENTICATION_MESSAGE } from "@/constants"
import { GET_HTTP_CLIENT } from "@/api"
import querystring from "querystring"
import Cognito from "@/utils/cognito"
import Cookies from "js-cookie"
import crypto from "crypto"
import { format, deleteFromLocalStorage } from "@/utils"
import { getBaseURL } from "@/utils"
import store from "@/plugins/vuex"

export default {
  /**
   * This action will login to the system using credentials.
   * @param {*} context is the store.
   * @param {*} payload contains username and password
   */
  async loginUsingCredentials(context, payload) {
    context.commit("setLoggingIn", true)
    context.commit("setInvalidCredentials", false)
    context.commit("setExceededAttempts", false)
    context.commit("setLoggedInUserDisabled", false)

    const authenticateUserResult = await Cognito.authenticateUser(
      context.getters.clientUserPoolId,
      context.getters.clientUserPoolClientId,
      payload.email,
      payload.password,
      payload.newPassword,
      payload.totp
    )

    if (authenticateUserResult) {
      if (authenticateUserResult.code === "NotAuthorizedException") {
        if (authenticateUserResult.message === COGNITO_AUTHENTICATION_MESSAGE.INVALID_CREDENTIALS) {
          context.commit("setInvalidCredentials", true)
        } else if (authenticateUserResult.message === COGNITO_AUTHENTICATION_MESSAGE.EXCEEDED_ATTEMPTS) {
          context.commit("setExceededAttempts", true)
        } else if (authenticateUserResult.message === COGNITO_AUTHENTICATION_MESSAGE.USER_DISABLED) {
          context.commit("setLoggedInUserDisabled", true)
        }
      } else if (authenticateUserResult === MFA.TOTP_PREFERRED) {
        context.commit("setTotpRequired", true)
      } else if (authenticateUserResult === COGNITO_AUTHENTICATION_MESSAGE.NEW_PASSWORD_REQUIRED) {
        context.commit("setNewPasswordRequired", true)
      } else {
        const loggedInSession = await Cognito.getSession(
          context.getters.clientUserPoolId,
          context.getters.clientUserPoolClientId
        )
        const loggedInUser    = JSON.parse(loggedInSession.getIdToken().decodePayload().user)
        context.commit("setLoggedInUser", loggedInUser)
        context.commit("setLoggedIn", true)
      }
    } else if (payload.totp) {
      context.commit("setInvalidTotp", true)
    }
    context.commit("setLoggingIn", false)
  },

  /**
   * This action will start IDP login process
   * @param {*} context context is the store
   */
  async loginUsingIDP(context) {
    context.commit("setLoggingIn", true)
    const verificationCode = crypto.randomBytes(16).toString("hex")
    context.commit("setVerificationCode", verificationCode)
    context.commit("setLoggingIn", false)

    const clientConfigurationId  = context.getters.clientConfigurationId
    const domainUrl              = format(
      process.env.VUE_APP_COGNITO_WEB_DOMAIN,
      clientConfigurationId,
      context.rootGetters["auth/region"]
    )
    const clientUserPoolClientId = context.getters.clientUserPoolClientId
    const redirectUrl            = format(process.env.VUE_APP_LOGIN_CALLBACK_URL, context.getters.clientName)
    window.location.href         = IDP_LOGIN_URL(
      domainUrl,
      clientUserPoolClientId,
      redirectUrl,
      verificationCode
    )
  },

  /**
   * This action will continue IDP login process after redirect from IDP
   * @param {*} context context is the store
   * @param {*} param1 object containing code and verificationCode from IDP
   */
  async continueLoginUsingIDP(context, { code, verificationCode }) {
    context.commit("setLoggingIn", true)

    if (verificationCode === context.getters.verificationCode) {
      const clientConfigurationId = context.getters.clientConfigurationId
      const httpClient            = GET_HTTP_CLIENT({
        baseURL: format(process.env.VUE_APP_COGNITO_WEB_DOMAIN,
          clientConfigurationId,
          context.rootGetters["auth/region"]
        ),
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      }, context)

      try {
        const tokensResponse = await httpClient.post("oauth2/token", querystring.stringify({
          grant_type  : "authorization_code",
          client_id   : context.getters.clientUserPoolClientId,
          redirect_uri: format(process.env.VUE_APP_LOGIN_CALLBACK_URL, context.getters.clientName),
          scope       : "email profile openid",
          code
        }))

        if (tokensResponse.status === HTTP_STATUS_CODE.OK) {
          Cookies.set("AccessToken", tokensResponse.data.access_token, COOKIE_OPTIONS)
          Cookies.set("IdToken", tokensResponse.data.id_token, COOKIE_OPTIONS)
          Cookies.set("RefreshToken", tokensResponse.data.refresh_token, COOKIE_OPTIONS)

          const idTokenPayload = JSON.parse(Buffer.from(tokensResponse.data.id_token.split(".")[1], "base64").toString("utf8"))
          if (idTokenPayload.user) {
            context.commit("setLoggedInUser", JSON.parse(idTokenPayload.user))
            context.commit("setLoggedIn", true)
          }
        }
      } catch (error) {
        const response = error.response
        if (response.status === HTTP_STATUS_CODE.BAD_REQUEST) {
          if (response.data.error.includes(COGNITO_AUTHENTICATION_MESSAGE.USER_VALIDATION_ERROR)) {
            store.dispatch("shared/notify", {
              type: "error",
              text: "1738"
            })
          }
        }
      }
    }
    context.commit("setLoggingIn", false)
  },

  async refreshTokens(context) {
    const clientConfigurationId = context.getters.clientConfigurationId
    const httpClient            = GET_HTTP_CLIENT({
      baseURL: format(process.env.VUE_APP_COGNITO_WEB_DOMAIN,
        clientConfigurationId,
        context.rootGetters["auth/region"]
      ),
      headers: {
        "content-type": "application/x-www-form-urlencoded"
      }
    }, context)

    const tokensResponse = await httpClient.post("oauth2/token", querystring.stringify({
      grant_type   : "refresh_token",
      client_id    : context.getters.clientUserPoolClientId,
      refresh_token: Cookies.get("RefreshToken")
    }))

    if (tokensResponse.status === HTTP_STATUS_CODE.OK) {
      Cookies.set("AccessToken", tokensResponse.data.access_token, COOKIE_OPTIONS)
      Cookies.set("IdToken", tokensResponse.data.id_token, COOKIE_OPTIONS)

      const idTokenPayload = JSON.parse(Buffer.from(tokensResponse.data.id_token.split(".")[1], "base64").toString("utf8"))
      context.commit("setLoggedInUser", JSON.parse(idTokenPayload.user))
      return
    }
    context.commit("setLoggedIn", false)
  },

  /**
   * This action will initiate password reset procedure.
   * @param {*} context is the store
   */
  async forgotPassword(context, payload) {
    context.commit("setRequestingPasswordReset", true)

    const forgotPasswordResult = await Cognito.forgotPassword(
      context.getters.clientUserPoolId,
      context.getters.clientUserPoolClientId,
      payload
    )

    if (forgotPasswordResult) {
      context.commit("setPasswordResetRequested", true)
    }

    context.commit("setRequestingPasswordReset", false)
  },

  /**
   * This action will initiate password reset procedure.
   * @param {*} context is the store
   */
  async resetPassword(context, payload) {
    context.commit("setPasswordExpired", false)

    const confirmPasswordResult = await Cognito.confirmPassword(
      context.getters.clientUserPoolId,
      context.getters.clientUserPoolClientId,
      payload.username,
      payload.code,
      payload.password
    )

    if (confirmPasswordResult) {
      context.commit("setPasswordReset", true)
    } else {
      context.commit("setPasswordExpired", true)
    }
    context.commit("setResettingPassword", false)
  },

  /**
   * This action will fetch a unique generated TOTP for the user account.
   * @param {*} context is the store
   */
  async generateSharedSecretCode(context) {
    const associateSoftwareTokenResult = await Cognito.associateSoftwareToken(
      context.getters.clientUserPoolId,
      context.getters.clientUserPoolClientId
    )

    if (associateSoftwareTokenResult) {
      context.commit("setSecretCode", associateSoftwareTokenResult)
    }
  },

  /**
  * This action will register user's entered TOTP code and
  * mark the user's software token MFA status as "verified".
  * @param {*} context is the store.
  * @param {*} totpCode is the time based OTP code from authenticator app.
  */
  async verifySecretToken(context, payload) {
    context.commit("setVerifyingToken", true)

    const verifySoftwareTokenResult = await Cognito.verifySoftwareToken(
      context.getters.clientUserPoolId,
      context.getters.clientUserPoolClientId,
      payload
    )

    if (verifySoftwareTokenResult) {
      context.commit("setTokenVerified", true)
    } else {
      context.commit("setInvalidTotp", true)
    }

    context.commit("setVerifyingToken", false)
  },

  /**
  * This action will get user data based on access code.
  * @param {*} context is the store.
  */
  async loadUserData(context) {
    const getUserDataResult = await Cognito.getUserData(
      context.getters.clientUserPoolId,
      context.getters.clientUserPoolClientId
    )

    context.commit("setMfaEnabledForLoggedInUser", getUserDataResult && getUserDataResult.PreferredMfaSetting === MFA.TOTP_PREFERRED)
  },

  /**
  * This action will set user MFA preference to software token based.
  * @param {*} context is the store.
  * @param {*} payload indicates if MFA has to be set or reset
  */
  async assignUserMfaPreference(context, payload) {
    const setUserMfaPreferenceResult = await Cognito.setUserMfaPreference(
      context.getters.clientUserPoolId,
      context.getters.clientUserPoolClientId,
      payload
    )

    if (setUserMfaPreferenceResult === MFA.SUCCESS) {
      context.commit("setMfaEnabledForLoggedInUser", payload)
      context.commit("setMfaEnabled", payload)
      if (!payload) {
        context.commit("setTokenVerified", undefined)
        context.commit("setSecretCode", undefined)
      }
    }
  },

  async changePassword(context, payload) {
    context.commit("setPasswordChanged", false)
    context.commit("setExceededAttempts", false)
    context.commit("setInvalidCredentials", false)
    context.commit("setChangingPassword", true)

    const changePasswordResult = await Cognito.changePassword(
      context.getters.clientUserPoolId,
      context.getters.clientUserPoolClientId,
      payload.current,
      payload.new
    )

    if (changePasswordResult) {
      if (changePasswordResult === "SUCCESS") {
        context.commit("setPasswordChanged", true)
      } else if (changePasswordResult.code === "NotAuthorizedException") {
        context.commit("setInvalidCredentials", true)
      } else if (changePasswordResult.code === "LimitExceededException") {
        context.commit("setExceededAttempts", true)
      }
    }
    context.commit("setChangingPassword", false)
  },

  /**
  * This action will get the region of the client.
  * @param {*} context is the store.
  */
  async loadRegion(context) {
    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], "/v1/getRegion")
    }, context)

    const regionResponse = await httpClient.get()

    if (regionResponse.status === HTTP_STATUS_CODE.OK) {
      const region = regionResponse.data.region
      context.commit("setRegion", region)
    }
  },

  /**
   * This action will log user out of the system and clear store.
   * @param {*} context is the store.
   */
  async logout(context) {
    const clientConfigurationId = context.getters.clientConfigurationId
    const domainUrl             = format(
      process.env.VUE_APP_COGNITO_WEB_DOMAIN,
      clientConfigurationId,
      context.rootGetters["auth/region"]
    )
    const redirectUrl           = format(process.env.VUE_APP_LOGOUT_CALLBACK_URL, context.getters.clientName)
    const isDefaultSsoEnabled   = context.rootGetters["configurations/isDefaultSsoEnabled"]

    context.dispatch("users/reset", undefined, { root: true })
    context.dispatch("shared/reset", undefined, { root: true })
    context.dispatch("accesses/reset", undefined, { root: true })
    context.dispatch("domains/reset", undefined, { root: true })
    context.dispatch("configurations/reset", undefined, { root: true })
    context.dispatch("channels/reset", undefined, { root: true })
    context.dispatch("users/reset", undefined, { root: true })
    context.dispatch("groups/reset", undefined, { root: true })
    context.dispatch("issues/reset", undefined, { root: true })
    context.dispatch("reports/reset", undefined, { root: true })
    context.dispatch("organisationCodes/reset", undefined, { root: true })
    context.dispatch("roles/reset", undefined, { root: true })
    context.dispatch("policies/reset", undefined, { root: true })
    context.dispatch("issueStatuses/reset", undefined, { root: true })
    context.dispatch("issueResolutions/reset", undefined, { root: true })
    context.dispatch("translationPreferences/reset", undefined, { root: true })
    context.dispatch("fields/reset", undefined, { root: true })
    context.dispatch("messages/reset", undefined, { root: true })
    context.dispatch("messageItems/reset", undefined, { root: true })
    context.dispatch("languages/reset", undefined, { root: true })
    context.dispatch("exceptions/reset", undefined, { root: true })
    context.dispatch("translations/reset", undefined, { root: true })
    context.dispatch("support/reset", undefined, { root: true })
    context.dispatch("logs/reset", undefined, { root: true })
    context.dispatch("labels/reset", undefined, { root: true })
    context.dispatch("issueDocuments/reset", undefined, { root: true })
    context.dispatch("kpis/reset", undefined, { root: true })
    context.dispatch("accessControl/reset", undefined, { root: true })
    context.dispatch("integrations/reset", undefined, { root: true })
    context.dispatch("exports/reset", undefined, { root: true })
    context.dispatch("optionLists/reset", undefined, { root: true })
    context.dispatch("optionListItems/reset", undefined, { root: true })
    context.dispatch("formTemplates/reset", undefined, { root: true })
    context.dispatch("optionLists/reset", undefined, { root: true })
    context.dispatch("optionListItems/reset", undefined, { root: true })
    context.dispatch("formTemplateConfigurations/reset", undefined, { root: true })
    context.dispatch("formInstances/reset", undefined, { root: true })
    context.dispatch("automations/reset", undefined, { root: true })
    context.dispatch("issueLinks/reset", undefined, { root: true })
    context.dispatch("issueTypes/reset", undefined, { root: true })
    context.dispatch("dataRetentionRules/reset", undefined, { root: true })
    context.dispatch("emailSubscriptions/reset", undefined, { root: true })
    context.dispatch("issueSearch/reset", undefined, { root: true })
    context.dispatch("workflows/reset", undefined, { root: true })
    context.dispatch("workflowAssociations/reset", undefined, { root: true })
    context.dispatch("transitions/reset", undefined, { root: true })
    context.dispatch("screens/reset", undefined, { root: true })
    context.dispatch("screenItems/reset", undefined, { root: true })
    context.dispatch("postFunctions/reset", undefined, { root: true })
    context.dispatch("replyTemplates/reset", undefined, { root: true })
    context.dispatch("feedback/reset", undefined, { root: true })

    deleteFromLocalStorage("workflows")
    deleteFromLocalStorage("changedWorkflows")

    const clientUserPoolClientId = context.getters.clientUserPoolClientId
    const clientUserPoolId       = context.getters.clientUserPoolId

    try {
      if (!isDefaultSsoEnabled) {
        await Cognito.logout(clientUserPoolId, clientUserPoolClientId)
      }
    } finally {
      context.dispatch("reset", undefined)
    }
    if (isDefaultSsoEnabled) {
      window.location.href = IDP_LOGOUT_URL(domainUrl, clientUserPoolClientId, redirectUrl)
    }
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  async reset(context) {
    Cookies.remove("AccessToken", COOKIE_OPTIONS)
    Cookies.remove("IdToken", COOKIE_OPTIONS)
    Cookies.remove("RefreshToken", COOKIE_OPTIONS)
    context.commit("setLoggingIn", false)
    context.commit("setLoggedIn", false)
    context.commit("setLoggedInUser", undefined)
    context.commit("setLoggedInUserRoleTypes", new Array())
    context.commit("setPasswordResetRequested", undefined)
    context.commit("setPasswordReset", undefined)
    context.commit("setResettingPassword", undefined)
    context.commit("setNewPasswordRequired", undefined)
    context.commit("setTotpRequired", undefined)
    context.commit("setSecretCode", undefined)
    context.commit("setTokenVerified", undefined)
    context.commit("setMfaEnabledForLoggedInUser", undefined)
    context.commit("setMfaEnabled", undefined)
    context.commit("setExceededAttempts", undefined)
    context.commit("setChangingPassword", false)
    context.commit("setPasswordChanged", false)
    context.commit("setPasswordExpired", false)
    context.commit("setInvalidTotp", false)
    context.commit("setInvalidCredentials", undefined)
    context.commit("setLoggedInUserDisabled", false)
  }
}