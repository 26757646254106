import { defaultTableHeaderClasses, defaultTableFooterOptions, defaultTableItemsPerPage } from "@/constants"

export const ISSUE_DOCUMENTS = {
  headers: [{
    text : "650",
    align: "left",
    value: "name",
    class: defaultTableHeaderClasses
  },
  {
    text : "651",
    align: "left",
    value: "type",
    class: defaultTableHeaderClasses
  },
  {
    text : "652",
    align: "left",
    value: "createdBy",
    class: defaultTableHeaderClasses
  },
  {
    text : "653",
    align: "left",
    value: "createdAt",
    class: defaultTableHeaderClasses
  },
  {
    value   : "actions",
    align   : "left",
    class   : defaultTableHeaderClasses,
    sortable: false
  }],
  itemsPerPage: defaultTableItemsPerPage,
  footer      : {
    ...defaultTableFooterOptions,
    itemsPerPageText: "65"
  }
}